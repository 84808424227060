html,
body {
  height: 100vh;
}

body,
#root {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
